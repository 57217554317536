<template>
  <div>

    <base-table ref="tableRef"
                rowKey="id"
                :columnsData="columns"
                :tableData="tableData">
      <template #toolbar>
        <div class="flex justify-between">
          <h4 class="text-lg font-bold">发票类目列表</h4>
          <a-button class="ml-3" type="primary" @click="handlerEdit('add')" html-type="submit">新增</a-button>
        </div>
      </template>
      <template #operation="{ record }">
        <a-button type="link" @click="handlerEdit('edit',record)">编辑</a-button>
      </template>
    </base-table>

    <add-modal v-if="isEidtShow" :show.sync="isEidtShow" :id="activeId" :type="type" />

  </div>
</template>

<script>
import { getInvoiceCategoryList } from "@/api/auto-invoice";
import pageData from "./columns";
import addModal from './components/add-modal.vue'

export default {
  components: { addModal },
  data() {
    return {
      ...pageData,
      tableData: [],

      type: '',
      activeId: '',
      isEidtShow: false,
      // isDetailShow: false,
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    async initData() {
      const { data, code } = await getInvoiceCategoryList();
      if (code === 0) {
        this.tableData = data.list
      }
    },

    handlerEdit(type, row) {
      this.type = type
      this.activeId = row ? row.id : ''
      this.isEidtShow = true
    },
    
    // handlerDetail(row) {
    //   this.activeId = row ? row.id : ''
    //   this.isDetailShow = true
    // },


  },
};
</script>

<style lang="less">
.goods-search-bar {
  margin-bottom: 16px;
  padding: 15px 24px 0;
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  .ant-form-item {
    margin-bottom: 5px;
  }
}
</style>
