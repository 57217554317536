
const columns = [
  {
    title: '类目ID',
    dataIndex: 'id',
    align: 'center',
    width: 90,
  },
  {
    title: '类目名',
    dataIndex: 'name',
    align: 'center',
  },
  {
    title: '类目编码',
    dataIndex: 'code',
    align: 'center',
  },
  {
    title: '税率（一般纳税人）',
    dataIndex: 'tax_rate_1',
    align: 'center',
    slots: {
      customRender: "tax_rate_1",
    },
    slotsType: "format",
    slotsFunc: (val)=>{ return val*100 + "%" },
  },
  {
    title: '税率（小规模纳税人）',
    dataIndex: 'tax_rate_2',
    align: 'center',
    slots: {
      customRender: "tax_rate_2",
    },
    slotsType: "format",
    slotsFunc: (val)=>{ return val*100 + "%" },
  },
  {
    title: '备注',
    dataIndex: 'remarks',
    align: 'center',
    width: "25%",
  },
  {
    title: '操作',
    dataIndex: 'operation',
    width: 90,
    align: 'center',
    slots: {
      customRender: 'operation'
    }
  },
]


export default {
  columns,
}
