<template>
  <div>
    <!-- 新增 编辑modal -->
    <a-modal :visible="show"
             width="600px"
             :title="type=='add'?'新增': '编辑'"
             @ok="handlerSave" @cancel="$emit('update:show', false)">
      <a-form-model ref="addForm"
                    :labelCol="{ span: 4 }"
                    :wrapperCol="{ span: 20 }"
                    :model="formData">
        <a-form-model-item prop="name" label="类目名">
          <a-input allowClear
                   v-model="formData.name"
                   placeholder="请输入类目名"></a-input>
        </a-form-model-item>

        <a-form-model-item prop="code" label="类目编码">
          <a-input allowClear
                   v-model="formData.code"
                   placeholder="请输入类目编码"></a-input>
        </a-form-model-item>

        <a-form-model-item prop="remarks" label="备注">
          <a-input style="width:100%" allowClear
                   v-model="formData.remarks"
                   placeholder="请输入备注"></a-input>
        </a-form-model-item>

        <a-form-model-item prop="tax_rate_1" label="税率">
          <div class="flex items-center">
            <span style="width:90px; text-align:right;">一般纳税人</span>
            <a-input-number class="ml-2 mr-2" style="width:100px" allowClear
                  v-model="percentRate1"
                  placeholder="请输入税率" :step="0.1" /> %
          </div>
          <div class="flex items-center">
            <span style="width:90px; text-align:right;">小规模纳税人</span>
            <a-input-number class="ml-2 mr-2" style="width:100px" allowClear
                  v-model="percentRate2"
                  placeholder="请输入税率" :step="0.1" /> %
          </div>
        </a-form-model-item>

      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { getInvoiceCategoryDetail, addInvoiceCategory, updateInvoiceCategory } from "@/api/auto-invoice";
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'add'
    },
    id: {
      type: [String, Number],
      default: ''
    }
  },
  data () {
    return {
      formData: {
        id: "",
        name: "",
        code: "",
        remarks: "",
        tax_rate_1: 0,
        tax_rate_2: 0,
      },

      percentRate1: 0,
      percentRate2: 0,
    }
  },
  mounted(){
    if(this.type === 'edit'){
      this.initData()
    }
  },
  methods: {
    async initData(){
      const res = await getInvoiceCategoryDetail({id: this.id})
      this.formData = res.data
      this.formData.id = this.id

      this.percentRate1 = (parseFloat(this.formData.tax_rate_1) || 0) * 100
      this.percentRate2 = (parseFloat(this.formData.tax_rate_2) || 0) * 100
    },


    handlerSave () {
      this.formData.tax_rate_1 = this.percentRate1 / 100
      this.formData.tax_rate_2 = this.percentRate2 / 100

      if (this.type === "add") {
        addInvoiceCategory(this.formData).then((res) => {
          if (res.code === 0) {
            this.$message.success("新增成功！")
            this.$emit('update:show', false)
            this.$parent.initData()
          }
        })
      } else {
        updateInvoiceCategory(this.formData).then((res) => {
          if (res.code === 0) {
            this.$message.success("修改成功！")
            this.$emit('update:show', false)
            this.$parent.initData()
          }
        })
      }
    },

  }
}
</script>

<style>
.recharge-account{
  align-items: center;
  
}
.recharge-account .ant-select{
  flex: 1;
  margin-right: 20px;
}

</style>